$secondary: #d9d9d9;
$white: #fdfdfd;
$primary: #f15935;
$info: #2a4490;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
* {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 16px;
}

/*home page*/

.features-padding{
  padding-top: 6rem;
}
.navbar-custom {
  background-color: #ffffffcb !important;
}
.show-quote-button{
  display: block;
}
.no-show-quote-button{
  display: none;
}
.carousel-caption {
  width: 100%;
  top: 40%;
  left: 0% !important;
}
.testing {
  position: relative;
  width: 100%;
  height: auto;
}
.highlight-card {
  position: absolute;
  width: 80vw;
  z-index: 150;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 25%);
}
.testimonial {
  .customer_inf {
    width: 50%;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    .testimonial_name {
      font-weight: 800;
      padding-bottom: 10px;
    }
    .testimonial_position {
      padding-top: 0px;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    filter: invert(100%);
    opacity: 0.3 !important;
  }
  .carousel-control-prev {
    left: -10% !important;
  }
  .carousel-control-next {
    right: -10% !important;
  }
}
.corousel-mobile {
  display: none;
}
.corousel-wide {
  display: block;
}

.background-image-trackorders {
  background: url("../src/images/trackorders.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.healthcareservices-banner {
  background: url("../src/images/carandskytower.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.healthcarelogi-banner {
  background: url("../src/images/carnightbridge.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.urgentdelivery-banner {
  background: url("../src/images/carnight.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Contact-Us-Info {
  width: 100%;
  height: 100%;
}
.list-group-services .list-group-item {
  background-color: #d9d9d9;
  border: 1px solid #f15935;
}
@media (max-width: 768px) {
  .logo {
    height: 30px;
  }
  .highlight-card-text {
    h5 {
      font-size: 16px;
    }
    p {
      display: none;
    }
  }
  .carousel-caption {
    top: 5%;
    padding: 20px;
  }
  .testimonial .customer_inf {
    width: 100%;
  }
  .corousel-mobile {
    display: block;
  }
  .corousel-wide {
    display: none;
  }

}

.logo-colour{
  filter: grayscale(100%);
  width: 100%;
  padding: 10%;
}
.logo-colour:hover{
  filter: grayscale(0%);
}

@media (max-width: 1150px) {
  .highlight-card {
    display: none;
  }
  .features-padding{
    padding-top: 1rem;
  }
}

/*home page end*/

/* contact us page start */
.background-image-contact {
  background: url("../src/images/contactus.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //height: 698px;
  // width: 100%;
}

.contact-info{
  padding: 0% 10% 0% 10%;
}
.quoteform{
  background-color: #282828;
  width: 400px;
}
.quoteform input{
  background-color: #515151;
  color: white;
  border: 0;
  width: 100%;
  margin: 2% 5% 2% 5%;
  border-radius: 0%;
}

.quoteform textarea{
  height: 30%;
}

.quoteform button{
  width: 100%;
  margin-top: 10px;
  border-radius: 0%;
}
.contact-info-card-body{
  height: 180px;
}
@media (max-width: 1000px) {
  .quoteform {
    width: 100%;
    position: inherit;
  }
  .contact-info-card-body{
    height: 100%;
  }
}
/* contact us page end */
/* About us start */
.background-image-aboutus {
  background: url("../src/images/auckland.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 656px;
}
/* About us end */


@import "~bootstrap/scss/bootstrap";
